<template>
  <div class='common_box parameter'>
    <div class="common_title">
      <h3>修改参数</h3>
      <div>
        <el-button class="btn_info" style="margin-right:18px" @click="$router.go(-1)">返回</el-button>
      </div>

    </div>
    <div class="argsForm">
      <el-form :model="argsForm" :rules="rules" label-width="185px" ref="argsForm" :hide-required-asterisk="true">
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item prop="running">
              <template slot="label">
                工作时间（单位S）
                <el-tooltip effect="dark" content="消毒人员在消毒间停留时长" placement="right">
                  <i class="el-icon-question"></i>
                </el-tooltip>
                ：
              </template>
              <el-input v-model="argsForm.running" placeholder="请输入工作时间" size="small">
              </el-input>
            </el-form-item>
            <el-form-item prop="start">
              <template slot="label">
                检测开始时间
                <el-tooltip effect="dark" content="0~0 → 全天检测(24小时制)，
                      0点即为24点，
                      监测结束时间不可小于开始时间" placement="right">
                  <i class="el-icon-question"></i>
                </el-tooltip>
                ：
              </template>
              <el-input v-model="argsForm.start" placeholder="请输入检测开始时间" size="small">
              </el-input>
            </el-form-item>
            <el-form-item prop="frequency" label="检测频率（单位S）： ">
              <el-input v-model="argsForm.frequency" placeholder="请输入检测频率" size="small">
              </el-input>
            </el-form-item>
            <el-form-item prop="mintimepairsremove" label="最小时间对（单位S）：">
              <el-input v-model="argsForm.mintimepairsremove" placeholder="请输入最小时间对" size="small">
              </el-input>
            </el-form-item>
            <el-form-item prop="mergeindexpiarsscale" label="合并索引对 ： ">
              <el-input v-model="argsForm.mergeindexpiarsscale" placeholder="请输入合并索引对" size="small">
              </el-input>
            </el-form-item>
            <el-form-item prop="videoframerate" label="最终视频的帧率 ： ">
              <el-input v-model="argsForm.videoframerate" placeholder="请输入最终视频的帧率" size="small">
              </el-input>
            </el-form-item>
            <el-form-item prop="imgmat" label="保存图片的格式 ：">
              <el-select v-model="argsForm.imgmat" placeholder="请选择保存图片的格式" size="small">
                <el-option label="jpg" value="jpg"></el-option>
                <el-option label="png" value="png"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item prop="gmtcreate" label="摄像头创建时间 ：">
              <el-input v-model="argsForm.gmtcreate" :disabled="true" size="small">
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item prop="working">
              <template slot="label">
                消毒柜时间（单位S）
                <el-tooltip effect="dark" content="消毒柜消毒所需时长" placement="right">
                  <i class="el-icon-question"></i>
                </el-tooltip>
                ：
              </template>
              <el-input v-model="argsForm.working" placeholder="请输入消毒柜时间" size="small">
              </el-input>
            </el-form-item>
            <el-form-item prop="stop" label="检测结束时间 ： ">
              <el-input v-model="argsForm.stop" placeholder="请输入检测结束时间" size="small">
              </el-input>
            </el-form-item>
            <el-form-item prop="grabsize" label="视频转图片帧率 ：">
              <el-input v-model="argsForm.grabsize" placeholder="请输入视频转图片帧率" size="small">
              </el-input>
            </el-form-item>
            <el-form-item prop="mergetimepairsscale" label="合并时间对（单位S）： ">
              <el-input v-model="argsForm.mergetimepairsscale" placeholder="请输入合并时间对" size="small">
              </el-input>
            </el-form-item>
            <el-form-item prop="minindexnumremove" label="最小索引对 ：">
              <el-input v-model="argsForm.minindexnumremove" placeholder="请输入最小索引对" size="small">
              </el-input>
            </el-form-item>
            <el-form-item prop="grabframerate" label="视频流抓取帧率 ：">
              <el-input v-model="argsForm.grabframerate" placeholder="请输入视频流抓取帧率" size="small">
              </el-input>
            </el-form-item>
            <el-form-item prop="videomat" label="保存视频的格式 ：">
              <el-select v-model="argsForm.videomat" placeholder="请选择保存视频的格式" size="small">
                <el-option label="mp4" value="mp4"></el-option>
                <el-option label="rmvb" value="rmvb"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>

        <el-form-item>
          <el-button type="primary" :loading="editLoading" @click="submitForm('argsForm')">确认修改</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import { getRoomArgs, editRoomArgs } from '@/api'
export default {
  data () {
    return {
      argsForm: {
        running: '',
        working: '',
        start: '',
        frequency: '',
        mintimepairsremove: '',
        mergeindexpiarsscale: '',
        videoframerate: '',
        imgmat: '',
        gmtcreate: '',
        stop: '',
        grabsize: '',
        mergetimepairsscale: '',
        minindexnumremove: '',
        grabframerate: '',
        videomat: ''
      },
      rules: {
        running: [
          { required: true, message: '请输入工作时间', trigger: 'blur' },
          { pattern: /^[0-9]*$/g, message: '请输入数字' }
        ],
        working: [
          { required: true, message: '请输入消毒柜时间', trigger: 'blur' },
          { pattern: /^[0-9]*$/g, message: '请输入数字' }
        ],
        start: [
          { required: true, message: '请输入检测开始时间', trigger: 'blur' },
          { pattern: /^[0-9]*$/g, message: '请输入数字' }
        ],
        frequency: [
          { required: true, message: '请输入检测频率', trigger: 'blur' },
          { pattern: /^[0-9]*$/g, message: '请输入数字' }
        ],
        mintimepairsremove: [
          { required: true, message: '请输入最小时间对', trigger: 'blur' },
          { pattern: /^[0-9]*$/g, message: '请输入数字' }
        ],
        mergeindexpiarsscale: [
          { required: true, message: '请输入合并索引对', trigger: 'blur' },
          { pattern: /^[0-9]*$/g, message: '请输入数字' }
        ],
        videoframerate: [
          { required: true, message: '请输入最终视频的帧率', trigger: 'blur' },
          { pattern: /^[0-9]*$/g, message: '请输入数字' }
        ],
        imgmat: [
          { required: true, message: '请选择保存图片的格式', trigger: 'change' }
        ],
        stop: [
          { required: true, message: '请输入检测结束时间', trigger: 'blur' },
          { pattern: /^[0-9]*$/g, message: '请输入数字' }
        ],
        grabsize: [
          { required: true, message: '请输入视频转图片帧率', trigger: 'blur' },
          { pattern: /^[0-9]*$/g, message: '请输入数字' }
        ],
        mergetimepairsscale: [
          { required: true, message: '请输入合并时间对', trigger: 'blur' },
          { pattern: /^[0-9]*$/g, message: '请输入数字' }
        ],
        minindexnumremove: [
          { required: true, message: '请输入最小索引对', trigger: 'blur' },
          { pattern: /^[0-9]*$/g, message: '请输入数字' }
        ],
        grabframerate: [
          { required: true, message: '请输入视频流抓取帧率', trigger: 'blur' },
          { pattern: /^[0-9]*$/g, message: '请输入数字' }
        ],
        videomat: [
          { required: true, message: '请选择保存视频的格式', trigger: 'change' }
        ],
      },
      editLoading: false
    };
  },
  created () {
    this.init()
  },
  mounted () {

  },
  methods: {
    init () {
      getRoomArgs(this.$route.query.id).then(res => {
        this.argsForm = res.data.data
      })
    },
    submitForm () {
      this.$refs.argsForm.validate((valid) => {
        if (valid) {
          const params = {
            ...this.argsForm,
            roomid: this.$route.query.id
          }
          editRoomArgs(this.$tools.concatParams(params)).then(() => {
            this.$message.success('房间参数修改成功')
            this.init()
          })
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
  }
};
</script>

<style scoped lang="scss">
.parameter {
  .argsForm {
    ::v-deep .el-form {
      width: 60%;
      margin: 0 auto;
      .el-form-item__label {
        color: #30c6fe;
      }
      .el-form-item__content {
        text-align: right;
        .el-input {
          .el-input__inner {
            background: none;
            border: 1px solid #30c6fe;
            color: #fff;
          }
        }
        .is-disabled {
          .el-input__inner {
            border: none;
          }
        }
        .el-select {
          width: 100%;
        }
        .el-textarea {
          .el-textarea__inner {
            background: none;
            border: 1px solid #30c6fe;
            color: #fff;
          }
        }
      }
    }
  }
}
</style>
